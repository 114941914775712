import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';

// hooks
import useRefCustom from '../hooks/useRefCustom';
import useTTToolFunction from '../hooks/useTTToolFunction';
import { delayFunc } from '../utils/others';
import { ExtractResponse } from '../utils/tool';

const initialState = {
  TTUser: {},
  following: null,
  followers: null,
  friends: null,
  recommend: null,
  isFetching: false,
  // Main funcs
  findAuthor: () => {},
  onLoadFollowing: () => {},
  onLoadFollowers: () => {},
  onLoadFriends: () => {},
  onLoadRecommend: () => {},
  checkExtensionActive: () => {},
  getExtensionVersion: () => {},
  stopQueue: () => {},
};

const TTContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
TTProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function TTProvider({ children }) {
  const {
    getProfile,
    getFollowing,
    getFollowers,
    getFriends,
    getRecommend,
    checkExtensionActive,
    getExtensionVersion,
    stopQueue,
  } = useTTToolFunction(
    () => {},
    () => {}
  );

  const { user: userAuth } = useAuth();

  const [user, setUserRef, userRef] = useRefCustom(null);

  const [following, setFollowing, followingRef] = useRefCustom(null);

  const [followers, setFollowers, followersRef] = useRefCustom(null);

  const [friends, setFriends, friendsRef] = useRefCustom(null);

  const [recommend, setRecommend, recommendRef] = useRefCustom(null);

  const [isFetching, setIsFetching, isFetchingRef] = useRefCustom(false);

  useEffect(() => {
    setUserRef(userAuth);
  }, [userAuth]);

  // Tiktok user data
  const [TTUser, setTTUser, TTUserRef] = useRefCustom(null);

  //  ---------------------------------------------------------------------

  const findAuthor = (uid) => {
    try {
      const data = [
        ...(followingRef?.current || []),
        ...(followersRef?.current || []),
        ...(friendsRef?.current || []),
        ...(recommendRef?.current || []),
      ];
      return data?.find((item) => item?.uid === uid);
    } catch (error) {
      return null;
    }
  };

  const onGetProfile = async () => {
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const profile = await getProfile();
      if (!TTUserRef?.current) {
        setTTUser(profile);
      }
      setIsFetching(false);
    }
  };

  const onListenUsers = (name = 'following', response = null) => {
    if (response) {
      const users = ([response] || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      const data = ExtractResponse?.extractUserList(users);
      console.log('onListenFollowing data', data);
      switch (name) {
        case 'following':
          {
            const current = followingRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setFollowing(temp);
          }
          break;
        case 'followers':
          {
            const current = followersRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setFollowers(temp);
          }
          break;
        case 'friends':
          {
            const current = friendsRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setFriends(temp);
          }
          break;
        case 'recommend':
          {
            const current = recommendRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setRecommend(temp);
          }
          break;
        default:
          break;
      }
    }
  };

  const onLoadFollowing = async () => {
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getFollowing(uid, (res) => {
          onListenUsers('following', res);
        });
        setFollowing(data);
      }
      setIsFetching(false);
    }
  };

  const onLoadFollowers = async () => {
    // xuanhiepreviewbc
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getFollowers(uid, (res) => {
          onListenUsers('followers', res);
        });
        setFollowers(data);
      }
      setIsFetching(false);
    }
  };

  const onLoadFriends = async () => {
    // xuanhiepreviewbc
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getFriends(uid, (res) => {
          onListenUsers('friends', res);
        });
        setFriends(data);
      }
      setIsFetching(false);
    }
  };

  const onLoadRecommend = async () => {
    // xuanhiepreviewbc
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getRecommend(uid, (res) => {
          onListenUsers('recommend', res);
        });
        setFriends(data);
      }
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (user) {
      onGetProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //  ---------------------------------------------------------------------
  return (
    <TTContext.Provider
      value={useMemo(
        () => ({
          TTUser,
          following,
          followers,
          friends,
          recommend,
          isFetching,
          // Main funcs
          findAuthor,
          onLoadFollowing,
          onLoadFollowers,
          onLoadFriends,
          onLoadRecommend,
          checkExtensionActive,
          getExtensionVersion,
          stopQueue,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [TTUser, following, followers, friends, recommend, isFetching]
      )}
    >
      {children}
    </TTContext.Provider>
  );
}

export { TTProvider, TTContext };
