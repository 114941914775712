import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LeadProvider } from '../contexts/LeadContext';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// layouts
import TTLayout from '../layouts/tiktok';
import Page404 from '../pages/Page404';
import { InteractiveProvider } from '../sections/whitelabel/tiktok/interactive/context/InteractiveContext';
import { lazyRetry, Loadable } from './lazy';
import { PATH_TIKTOK_TOOL } from './paths';
// =====================================================================

const InteractiveDashboard = Loadable(
  lazy(() => lazyRetry(() => import('../pages/whitelabel/tiktok/InteractiveDashboard')))
);
const AffiliateDashboard = Loadable(
  lazy(() => lazyRetry(() => import('../pages/whitelabel/tiktok/AffiliateDashboard')))
);

const smtRoutes = [
  // Main Routes
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '/',
    element: <TTLayout />,
    children: [
      { element: <Navigate to={PATH_TIKTOK_TOOL.interactive.root} replace />, index: true },
      {
        path: 'interactive',
        element: (
          <InteractiveProvider>
            <InteractiveDashboard />
          </InteractiveProvider>
        ),
        index: true,
      },
      {
        path: 'affiliate',
        element: <AffiliateDashboard />,
      },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default smtRoutes;
