import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { delayFunc, parseJSON } from '../../utils/others';
import useRefCustom from '../useRefCustom';
import useToolFunction from '../useToolFunction';

const DEFAULT_VALUE = {};
const LOCAL_STORAGE_NAME = 'tool-group-stats';
// Time expire from the first crawl
const EXPIRE_TIME = 7 * 24 * 60 * 60 * 1000;

function useGroupStats() {
  const { getGroupActivity } = useToolFunction();

  const [, setGroupStats, groupStatsRef] = useRefCustom(DEFAULT_VALUE);

  const [, setGroupIdsNeedCrawl, groupIdsNeedCrawlRef] = useRefCustom([]);

  const onLoadPreviousData = () => {
    try {
      const data = localStorage.getItem(LOCAL_STORAGE_NAME);
      if (data) {
        const localStats = parseJSON(data, {});
        // Is object and type of keys must be a number
        const objKeys = Object.keys(localStats);
        if (typeof localStats === 'object' && objKeys.every((key) => /^\d+$/.test(key))) {
          const temp = objKeys?.reduce((res, key) => {
            const item = localStats[key] || {};

            try {
              item.crawledAt = new Date(item?.crawledAt);
            } catch (error) {
              item.crawledAt = new Date();
            }

            // Skip old data
            if (item?.crawledAt?.getTime() > Date.now() - EXPIRE_TIME) {
              return { ...res, [key]: item };
            }
            return res;
          }, {});
          setGroupStats(temp);
          localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(temp));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setNewStats = (groupId, value) => {
    try {
      if (!value || !groupId) {
        return;
      }
      const current = groupStatsRef?.current || {};

      current[groupId] = { ...value, crawledAt: new Date() };

      setGroupStats({ ...current });
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(current));
    } catch (error) {
      console.log(error);
    }
  };

  const loopTasks = async (callback) => {
    try {
      // We currently use ref so it will directly set new value when you pop item from an array
      const groupId = groupIdsNeedCrawlRef?.current?.length !== 0 ? groupIdsNeedCrawlRef?.current?.pop() : null;
      if (!groupId) {
        return;
      }

      const data = await getGroupActivity(groupId);
      if (data) {
        callback(groupId, data);
        setNewStats(groupId, data);
      }
      await delayFunc(300);
      loopTasks(callback);
    } catch (error) {
      console.log(error);
    }
  };

  const asyncGetGroupsData = (groupIds, callback) => {
    try {
      setGroupIdsNeedCrawl(groupIds);
      loopTasks(callback);
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupStats = () => groupStatsRef?.current;

  return {
    onLoadPreviousData,
    asyncGetGroupsData,
    getGroupStats,
  };
}

export default useGroupStats;
