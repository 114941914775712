import PropTypes from 'prop-types';
/* eslint-disable */
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useRef, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';
import usePostMessageRequest from '../hooks/usePostMessageRequest';
import useRefCustom from '../hooks/useRefCustom';
import { EmitterService } from '../utils/event';
import { ExtractResponse } from '../utils/tool';
import { generateCombinations, randomInRange } from '../utils/others';

// In seconds
const CANCEL_MSG = `Canceling tasks!`;
const ABORT_MSG = 'Operation canceled';

const initialState = {
  // Emitter
  emitter: { on: () => {}, off: () => {}, send: () => {} },
  // Funcs
  getProfile: () => {},
  getFollowing: () => {},
  getFollowers: () => {},
  getFriends: () => {},
  getRecommend: () => {},
  searchVideosByKeyword: () => {},
  getVideosOfUser: (username, amount, callback) => {},
  commentVideo: (username, videoId, text) => {},
  interactVideo: (username, videoId, type) => {},
  repostVideo: (username, videoId) => {},
  followUser: (username) => {},
  stopQueue: () => {},
  getExtensionVersion: () => {},
  checkExtensionActive: () => {},
  interactFunc: () => {},
};

const TTFunctionContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
TTFunctionProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function TTFunctionProvider({ children }) {
  const { sendRequest } = usePostMessageRequest();

  const [emitter, setEmitter] = useState(new EmitterService());

  const responseHelper = new ExtractResponse();

  const slowdown = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const [, setUser, userRef] = useRefCustom(null);

  useEffect(() => {
    setUser(user);
  }, [user]);

  // Control flow, queue, tasks
  let controller = useRef(null);
  let signal = useRef(null);

  const getExtensionVersion = async () => {
    const payload = await sendRequest('GET_VERSION');
    return payload;
  };

  const checkExtensionActive = async () => {
    return await sendRequest('CHECK_ACTIVE_EXTENSION');
  };

  // Get current tiktok profile
  const getProfile = async () => {
    try {
      const response = await sendRequest('GET_PROFILE');
      if (response) {
        const {
          userInfo: { stats, user },
        } = response;
        return {
          image: user?.avatarMedium,
          name: `@${user?.uniqueId}`,
          uid: user?.uniqueId,
          stats,
        };
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Get current following
  const getFollowing = async (uid, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_FOLLOWING', { uid, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get current followers
  const getFollowers = async (uid, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_FOLLOWERS', { uid, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get current friends
  const getFriends = async (uid, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_FRIENDS', { uid, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get current recommend
  const getRecommend = async (uid, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_RECOMMEND', { uid, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get videos by keyword
  const searchVideosByKeyword = async (keyword, amount = 100, callback = () => {}) => {
    try {
      if (!keyword) {
        return [];
      }
      const response = await sendRequest(
        'SEARCH_VIDEOS',
        { keyword, amount, stream: true },
        signal?.current,
        null,
        (data) => {
          const formartedData = responseHelper.extractSearchVideos([data]);
          callback(formartedData);
        }
      );
      return responseHelper?.extractSearchVideos(response || [])?.slice(0, amount);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getVideosOfUser = async (username, amount = 100, callback = () => {}) => {
    try {
      if (!username) {
        return [];
      }
      const response = await sendRequest(
        'USER_VIDEOS',
        { username, amount, stream: true },
        signal?.current,
        null,
        (data) => {
          const formartedData = responseHelper.extractUserVideos([data]);
          console.log(formartedData);
          if (callback) {
            callback(formartedData);
          }
        }
      );
      return responseHelper?.extractUserVideos(response || [])?.slice(0, amount);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // comment video
  const commentVideo = async (username, videoId, text) => {
    try {
      if (!username || !videoId || !text) {
        return false;
      }
      return await sendRequest('COMMENT_VIDEO', { username, videoId, text }, signal?.current, 100);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // follow user
  const followUser = async (username) => {
    try {
      if (!username) {
        return false;
      }
      return await sendRequest('FOLLOW_USER', { username }, signal?.current);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // follow interact video
  const interactVideo = async (username, videoId, type) => {
    try {
      if (!username || !videoId || !type) {
        return false;
      }
      const VALID_TYPE = ['LIKE', 'SAVE'];
      if (VALID_TYPE?.indexOf(type) === -1) {
        console.log('[interactVideo]: Invalid type');
        return false;
      }
      return await sendRequest('INTERACT_VIDEO', { username, videoId, interactType: type }, signal?.current, 40);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // repost video
  const repostVideo = async (username, videoId) => {
    try {
      if (!username || !videoId) {
        return false;
      }
      return await sendRequest('REPOST', { username, videoId }, signal?.current, 40);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // view video
  const viewProfile = async (username) => {
    try {
      if (!username) {
        return false;
      }
      return await sendRequest('VIEW_PROFILE', { username }, signal?.current, 40);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const extractSpinData = (item) => {
    const { files, content } = item;
    // Get spin content
    let modifiedContent = content;
    if (item?.spin && item?.spin?.length !== 0) {
      let spinData = item?.spin?.pop();
      // Update head
      item?.spin?.unshift(spinData);
      if (spinData) {
        Object.keys(spinData).forEach((key) => {
          modifiedContent = modifiedContent.replace(`{{${key}}}`, spinData[key]);
        });
      }
    }
    return { files, content: modifiedContent };
  };

  const getRandomContent = (comBinationsContents) => {
    const item = comBinationsContents[randomInRange({ from: 0, to: comBinationsContents?.length - 1 })];
    return extractSpinData(item);
  };

  const interactFunc = async (data, callback) => {
    try {
      restartSignal();

      const { targetId, video, delay, actions, contents } = data || {};

      const comBinationsContents = contents.map((item) => ({
        ...item,
        spin: generateCombinations(item?.spinContents || {}),
      }));

      const ACTIONS = {
        COMMENT: 'comment',
        FOLLOW: 'follow',
        LIKE: 'like',
        SAVE: 'save',
        REPOST: 'repost',
        PROFILE: 'profile',
      };

      // Check actions need execute
      const settings = {
        allowComment: actions?.indexOf(ACTIONS?.COMMENT) !== -1,
        allowFollow: actions?.indexOf(ACTIONS?.FOLLOW) !== -1,
        allowLike: actions?.indexOf(ACTIONS?.LIKE) !== -1,
        allowSave: actions?.indexOf(ACTIONS?.SAVE) !== -1,
        allowRepost: actions?.indexOf(ACTIONS?.REPOST) !== -1,
        allowProfile: actions?.indexOf(ACTIONS?.PROFILE) !== -1,
      };

      // Tasks
      const tasks = [];

      // Comment video
      if (settings.allowComment && video?.id) {
        const { content } = getRandomContent(comBinationsContents);
        const commentTask = async () => {
          const res = await commentVideo(targetId, video?.id, content);
          callback({
            targetId,
            target: video?.author,
            result: { comment: { success: !!res } },
          });
        };
        tasks.push(commentTask);
      }

      // Follow
      if (settings.allowFollow && targetId) {
        const followTask = async () => {
          const res = await followUser(targetId);
          callback({
            targetId,
            target: video?.author,
            result: { follow: { success: !!res } },
          });
        };
        tasks.push(followTask);
      }

      // Like
      if (settings.allowLike && video?.id) {
        const likeTask = async () => {
          const res = await interactVideo(targetId, video?.id, 'LIKE');
          callback({
            targetId,
            target: video?.author,
            result: { like: { success: !!res } },
          });
        };
        tasks.push(likeTask);
      }

      if (settings.allowProfile) {
        const viewProfileTask = async () => {
          const res = await viewProfile(targetId);
          callback({
            targetId,
            target: video?.author,
            result: { view_profile: { success: !!res } },
          });
        };
        tasks.push(viewProfileTask);
      }

      if (settings.allowRepost && video?.id) {
        const repostTask = async () => {
          const res = await repostVideo(targetId, video?.id);
          callback({
            targetId,
            target: video?.author,
            result: { repost: { success: !!res } },
          });
        };
        tasks.push(repostTask);
      }
      if (settings.allowSave && video?.id) {
        const saveTask = async () => {
          const res = await interactVideo(targetId, video?.id, 'SAVE');
          callback({
            targetId,
            target: video?.author,
            result: { save: { success: !!res } },
          });
        };
        tasks.push(saveTask);
      }

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }
        await task();

        const isLastTask = tasks?.length - 1 === taskIndex;
        if (!isLastTask) {
          await delayFunc(randomInRange(delay) * 1000, signal?.current);
        }
      }

      return {};
    } catch (error) {
      console.log(error?.message);
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };
  // ===================================================================
  const restartSignal = () => {
    // Restart signal
    const newController = new AbortController();
    controller.current = newController;
    signal.current = newController?.signal;

    signal?.current?.addEventListener('abort', () => {
      throw new Error(ABORT_MSG);
    });
  };

  const onStop = (reason) => {
    if (['checkpoint', 'retry']?.indexOf(reason) !== -1) {
      emitter.send(reason);
    }
  };

  // Stop current queue
  const stopQueue = async (sendMessage = false, reason = '') => {
    try {
      if (reason === 'checkpoint' || reason === 'manual') {
        controller?.current?.abort();
        emitter?.send('stop');
      }
      if (sendMessage) {
        onStop(reason);
      }
      return { reason };
    } catch (error) {
      console.log(error);
      return { reason: error?.message };
    } finally {
      slowdown.onFalse();
    }
  };

  return (
    <TTFunctionContext.Provider
      value={{
        getProfile,
        getFollowing,
        getFollowers,
        getFriends,
        getRecommend,
        searchVideosByKeyword,
        getVideosOfUser,
        commentVideo,
        interactVideo,
        repostVideo,
        followUser,
        stopQueue,
        getExtensionVersion,
        checkExtensionActive,
        interactFunc,
        // Emitter
        emitter,
      }}
    >
      {children}
    </TTFunctionContext.Provider>
  );
}

export { TTFunctionProvider, TTFunctionContext };
