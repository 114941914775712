import { useState, useCallback } from 'react';
import uuidv4 from '../utils/uuidv4';
import useBoolean from './useBoolean';

const usePostMessageRequest = () => {
  const loading = useBoolean();

  const [error, setError] = useState(null);

  const ALLOW_ORIGINS = [
    'https://fbtool.net',
    'https://dev.fbtool.net',
    'https://beta.fbtool.net',
    'http://localhost:3000',
    'https://toptoptool.net',
    'https://dev.toptoptool.net',
  ];

  const ALLOW_RECEIVE_TYPES = [
    'R_GET_VERSION',
    'R_CHECK_ACTIVE_EXTENSION',
    'R_FB_DATA',
    'R_UPLOAD_IMAGES',
    'R_FETCH',
    'R_SEND_INBOX',
    'R_SEND_PAGE_INBOX',
    'R_UPLOAD_VIDEO',
    'R_CHECK_INTERNET',
    'R_REMOVE_PENDING_POSTS',
    // Tiktok
    'R_GET_PROFILE',
    'R_GET_FOLLOWING',
    'R_GET_FOLLOWERS',
    'R_SEARCH_VIDEOS',
    'R_COMMENT_VIDEO',
    'R_FOLLOW_USER',
    'R_INTERACT_VIDEO',
    'R_REPOST',
    'R_USER_VIDEOS',
    'R_GET_FRIENDS',
    'R_GET_RECOMMEND',
    'R_VIEW_PROFILE',
  ];

  // Add signal here
  const sendRequest = useCallback((type, payload = {}, signal = null, timeout = 60, callback = () => {}) => {
    let timer = null;
    const requestId = uuidv4();
    const messagePayload = { payload: { ...payload, requestId }, type };
    const isStream = payload?.stream;
    return new Promise((resolve, reject) => {
      const handleMessage = (event) => {
        const { type } = event?.data || {};

        if (ALLOW_ORIGINS?.indexOf(event?.origin) === -1 || ALLOW_RECEIVE_TYPES.indexOf(type) === -1) {
          return;
        }
        loading.onFalse();

        const { requestId: responseId, data, error, isCompleted } = event?.data?.payload || {};
        if (responseId === requestId) {
          if (!isStream) {
            window.removeEventListener('message', handleMessage);
          }
          if (timer) {
            clearTimeout(timer);
          }
          if (error) {
            reject(error);
          } else {
            // Support stream data
            if (isStream && isCompleted) {
              window.removeEventListener('message', handleMessage);
              resolve(data);
            }

            if (isStream && !isCompleted) {
              callback(data);
            } else {
              resolve(data);
            }
          }
        }
      };

      loading.onTrue();
      setError(null);
      window.addEventListener('message', handleMessage);
      window.postMessage(messagePayload, '*');

      if (timeout && typeof timeout === 'number') {
        timer = setTimeout(() => {
          window.removeEventListener('message', handleMessage);
          reject(new Error(`Timeout by watting for ${type} progress`));
        }, timeout * 1000);
      }
      if (signal) {
        signal?.addEventListener('abort', () => {
          if (timer) {
            clearTimeout(timer);
          }
          window.removeEventListener('message', handleMessage);
          reject(new Error('Operation canceled'));
        });
      }
    });
  }, []);

  return { sendRequest, loading: loading?.value, error };
};

export default usePostMessageRequest;
