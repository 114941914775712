const en = {
  info: {
    following: 'Following',
    followers: 'Followers',
    like: 'Like',
  },
  interact: {
    setting: {
      keyword: 'Keyword',
      comment: 'Comment',
      heart: 'Like',
      view_profile: 'View profile',
      follow: 'Follow',
      save: 'Save',
      repost: 'Repost',
    },
    types: {
      following: 'Followed',
      follower: 'Follower',
      friend: 'Friend',
      recommend: 'Recommended',
    },
    form: {
      comment_amount: 'Posts amount',
      like_amount: 'Posts amount',
    },
  },
  export: {
    comment: 'Comment',
    heart: 'Like',
    view_profile: 'View profile',
    save: 'Save',
    repost: 'Repost',
    follow: 'Follow',
  },
  popup: {
    pricing: {
      title: 'Tools to support interaction on ',
      sub: 'Tiktok',
      heading: 'PRICE LIST',
      features: [
        'No need to log in with Tiktok ID and password 🔒',
        'AI rewrites content for free',
        'Save favorite group',
      ],
      tier: 'Tier',
      tiers: [
        {
          tier: 1,
          name: 'BASIC',
          cost: {
            months: 100000,
            year: 50000,
          },
          features: [
            {
              text: 'Comment',
              info: 'Comment',
            },
            {
              text: 'AI spin',
              info: 'Every time you post, the tool automatically uses AI to rewrite the content to avoid Spam.',
            },
            {
              text: 'Heart drop',
              info: 'Heart drop',
            },
            {
              text: 'Repost',
              info: 'Repost',
            },
            {
              text: 'Save favorite user groups',
              info: 'Save favorite user groups',
            },
            {
              text: 'Follow',
              info: 'Follow',
            },
            {
              text: 'Save video',
              info: 'Save',
            },
            {
              text: 'View profile',
              info: 'View profile',
            },
          ],
        },
      ],
      monthly: 'Monthly',
      semi_annual: 'Semi-annual',
      annual: 'Every year',
      month_value: '100K',
      annual_value: '50K',
      discount: 'Save up to {{percent}}%',
      bill_annual: 'Year',
      bill_monthly: 'Month',
    },
  },
};

export default en;
