import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import useCommonToolContext from '../../../../../hooks/useCommonToolContext';
import useTTToolFunction from '../../../../../hooks/useTTToolFunction';
import useRefCustom from '../../../../../hooks/useRefCustom';
import { delayFunc, randomInRange } from '../../../../../utils/others';
import { EmitterService } from '../../../../../utils/event';
import useTTContext from '../../../../../hooks/useTTContext';
import { IS_LOCALHOST } from '../../../../../config';

const initialState = {
  isRunning: false,
  isLoading: false,
  emitterManageUI: { on: () => {}, off: () => {}, send: () => {} },
  actionsSetting: {},
  onCancel: () => {},
  onStartInteract: () => {},
  getActionsSetting: () => {},
  updateActionsSetting: () => {},
};

const InteractiveContext = createContext(initialState);

const ACTIONS_NEED_CONTENT = ['comment', 'like'];

const ACTIONS_NEED_VIDEO = ['comment', 'like', 'save', 'repost'];

const ACTIONS_RESULT = ['comments', 'likes', 'inbox', 'poke', 'remove_friend', 'view_profile'];

// Interact with DEFAULT_VIDEOS_NEED found in user profile if u have selected targets from the right corner or number video found on tiktok search engine
const DEFAULT_VIDEOS_NEED = IS_LOCALHOST ? 3 : 10;

export const SORT = 'sort-reactions';

// ---------------------- PROPS VALIDATE ---------------------
InteractiveProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function InteractiveProvider({ children }) {
  const [emitterManageUI, setEmitterManageUI] = useState(new EmitterService());

  const { onLoadFollowing, TTUser, findAuthor } = useTTContext();

  const [postQueues, setPostQueues, postQueuesRef] = useRefCustom([]);

  const [actionsSetting, setActionsSetting, actionsSettingRef] = useRefCustom({});

  const isRunning = useBoolean();

  const isLoading = useBoolean();

  const [, setSetting, settingRef] = useRefCustom({});

  const [, setTargets, targetsRef] = useRefCustom([]);

  const [, setVideosSource, videosSourceRef] = useRefCustom([]);

  const taskRef = useRef();

  const { checkCommonData, updateTaskRunningStatus } = useCommonToolContext();

  const { interactFunc, searchVideosByKeyword, getVideosOfUser, stopQueue } = useTTToolFunction();

  // ==================================================================

  useEffect(() => {
    onLoadFollowing();
  }, [TTUser]);

  const interactCallback = (data) => {
    console.log('interactCallback', data);

    const { targetId, target, result } = data;

    const authorInfo = target || findAuthor(targetId);
    const author = {
      uid: authorInfo?.uniqueId,
      name: authorInfo?.nickname,
      image: authorInfo?.image || authorInfo?.avatarMedium,
    };

    const newArray = [...postQueuesRef?.current];
    let index = newArray.findIndex((item) => item?.uid === targetId);
    if (index === -1) {
      // Add new item
      const newItem = {
        author,
        uid: targetId,
      };
      index = newArray.push(newItem) - 1;
    }

    if (index !== -1) {
      const mapData = {
        comment: 'comments',
        like: 'likes',
        save: 'saves',
        repost: 'reposts',
      };
      Object.keys(result)?.forEach((key) => {
        if (['comment', 'like', 'save', 'repost']?.indexOf(key) !== -1) {
          const mainKey = mapData[key];
          const current = newArray[index][mainKey] || [];
          current.push(result[key]);
          newArray[index] = { ...newArray[index], [mainKey]: [...current] };
        } else {
          newArray[index] = { ...newArray[index], ...(result || {}) };
        }
      });
    }
    setPostQueues([...newArray]);
  };

  const loadVideosOfUser = async (targetId) => {
    try {
      const { actions, amount } = settingRef?.current || {};

      const needPost = actions?.some((item) => ACTIONS_NEED_VIDEO.indexOf(item) !== -1);
      // Some actions need post to interact. Eg: comment, like
      const haveLikeAction = actions.indexOf('like') !== -1;
      const haveCommentAction = actions.indexOf('comment') !== -1;
      let finalVideosAmount = DEFAULT_VIDEOS_NEED;
      if (needPost) {
        if (haveLikeAction) {
          finalVideosAmount = Math.max(1, amount?.like || 1);
        }
        if (haveCommentAction) {
          finalVideosAmount = Math.max(1, amount?.comment || 1);
        }
      }
      const videos = await getVideosOfUser(targetId, finalVideosAmount);
      console.log('videos', videos);
      setVideosSource(videos || []);
    } catch (error) {
      console.log(error);
    }
  };

  const getTargetData = async () => {
    try {
      const video = getRemainsVideo();

      if (!video) {
        // Pop to interact
        const targetId = targetsRef?.current?.length !== 0 ? targetsRef?.current?.pop() : null;

        if (!targetId) {
          return null;
        }

        // Check need video ?
        const { actions } = settingRef?.current || {};
        const needVideo = actions?.some((item) => ACTIONS_NEED_VIDEO.indexOf(item) !== -1);
        if (!needVideo) {
          return { targetId, video: null };
        }

        await loadVideosOfUser(targetId);
        return { targetId, video: getRemainsVideo() };
      }
      return { targetId: video?.author?.uniqueId, video };
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const loopTask = async (contents) => {
    // Check have remains video ? -> Check remains target?
    const dataInfo = await getTargetData();
    console.log('dataInfo', dataInfo);

    if (!dataInfo) {
      onReset();
      return;
    }

    const { delay, actions } = settingRef?.current || {};
    const { from, to } = delay || { from: 60, to: 120 };
    const CYCLE_DELAY = randomInRange({ from, to }) * 1000;

    const { targetId, video } = dataInfo || {};
    console.log('Selected target id:', targetId);

    const data = {
      targetId,
      video,
      delay,
      actions,
      contents,
    };

    const { isOperationCanceled } = await interactFunc(data, interactCallback);
    if (isOperationCanceled) {
      console.log('Break cycle!! STOPPED.');
      onReset();
      return;
    }
    console.log('Run next cycle!');
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    const emptyVideos = !videosSourceRef?.current || videosSourceRef?.current?.length === 0;
    const emptyTargets = !targetsRef?.current || targetsRef?.current?.length === 0;

    if (emptyTargets && emptyVideos) {
      onReset();
      return;
    }

    taskRef.current = setTimeout(() => {
      loopTask(contents);
    }, CYCLE_DELAY);
  };

  const onReset = () => {
    setTargets([]);
    setVideosSource([]);
    isRunning.onFalse();
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }
  };

  const onStartInteract = async (data) => {
    if (!checkCommonData()) {
      return;
    }
    console.log('onStartInteract', data);
    /**
     *
     * Flow:
     * - Dont display queues before receive any result from action
     * - Sources to interact will get from search engine of tiktok or videos of user (high priority if user select least 1 targets)
     * - [
     *  + Select targets -> pop list targets to run
     *  + Search engine -> check action select -> check amount if select comment, heart -> by default we get about 50 videos to interact(if user select other actions)
     * ]
     */

    onReset();
    const { contents, setting, targets } = data;
    setSetting(setting);
    setPostQueues([]);
    try {
      if (targets?.length !== 0) {
        // Run with targets have been selected before
        setTargets([...targets]);
        isRunning.onTrue();
        loopTask(contents);
      } else if (setting?.keyword) {
        // Find videos by keywords then run
        isRunning.onTrue();
        await loadVideosFromKeyword();
        await interactWithVideos(contents);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ====================================================================================

  const loadVideosFromKeyword = async () => {
    try {
      const { actions, amount, keyword } = settingRef?.current || {};

      const needPost = actions?.some((item) => ACTIONS_NEED_CONTENT.indexOf(item) !== -1);
      // Some actions need post to interact. Eg: comment, like
      const haveLikeAction = actions.indexOf('like') !== -1;
      const haveCommentAction = actions.indexOf('comment') !== -1;
      let finalVideosAmount = DEFAULT_VIDEOS_NEED;
      if (needPost) {
        if (haveLikeAction) {
          finalVideosAmount = Math.max(1, amount?.like || 1);
        }
        if (haveCommentAction) {
          finalVideosAmount = Math.max(1, amount?.comment || 1);
        }
      }
      const videos = await searchVideosByKeyword(keyword, finalVideosAmount);
      setVideosSource(videos || []);
    } catch (error) {
      console.log(error);
    }
  };

  const getRemainsVideo = () => {
    try {
      const videos = videosSourceRef?.current || [];
      const temp = [...videos];
      const item = temp?.pop();
      setVideosSource([...temp]);
      return item;
    } catch (error) {
      return null;
    }
  };

  const interactWithVideos = async (contents) => {
    // Get
    const { delay, actions } = settingRef?.current || {};
    const { from, to } = delay || { from: 60, to: 120 };
    const CYCLE_DELAY = randomInRange({ from, to }) * 1000;
    const video = getRemainsVideo();
    console.log('video', video);
    if (!video) {
      onReset();
      return;
    }

    const data = {
      targetId: video?.author?.uniqueId,
      video,
      delay,
      actions,
      contents,
    };

    const { isOperationCanceled } = await interactFunc(data, interactCallback);
    if (isOperationCanceled) {
      console.log('Break cycle!! STOPPED.');
      onReset();
      return;
    }
    console.log('Run next cycle!');
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    if (!videosSourceRef?.current || videosSourceRef?.current?.length === 0) {
      onReset();
      return;
    }

    taskRef.current = setTimeout(() => {
      interactWithVideos(contents);
    }, CYCLE_DELAY);
  };

  // ====================================================================================

  // CONTROL SUBMIT BUTTON
  const onCancelPost = () => {
    stopQueue(false, 'manual');
    isRunning.onFalse();
    onReset();
    const temp = [...postQueues]?.filter((item) => ACTIONS_RESULT?.some((key) => item[key]));
    setPostQueues([...temp]);
  };

  const updateActionsSetting = (newValue) => {
    const isDiff = !_.isEqual(actionsSettingRef?.current, newValue);
    if (isDiff) {
      setActionsSetting(newValue);
    }
  };

  const getActionsSetting = () => {
    return actionsSettingRef?.current || {};
  };

  useEffect(() => {
    updateTaskRunningStatus(isRunning?.value);
  }, [isRunning?.value]);

  return (
    <InteractiveContext.Provider
      value={useMemo(
        () => ({
          postQueues,
          isRunning: isRunning.value,
          isLoading: isLoading.value,
          emitterManageUI,
          actionsSetting,
          onCancelPost,
          onStartInteract,
          getActionsSetting,
          updateActionsSetting,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRunning?.value, isLoading?.value, postQueues, actionsSetting]
      )}
    >
      {children}
    </InteractiveContext.Provider>
  );
}

export { InteractiveProvider, InteractiveContext };
