const vn = {
  info: {
    following: 'Đã follow',
    followers: 'Follower',
    like: 'Thích',
  },
  interact: {
    setting: {
      keyword: 'Từ khóa',
      comment: 'Bình luận',
      heart: 'Thả tim',
      view_profile: 'Xem profile',
      follow: 'Follow',
      save: 'Lưu',
      repost: 'Đăng lại',
    },
    types: {
      following: 'Đã follow',
      follower: 'Follower',
      friend: 'Bạn bè',
      recommend: 'Được đề xuất',
    },
    form: {
      comment_amount: 'Số bài',
      like_amount: 'Số bài',
    },
  },
  export: {
    comment: 'Bình luận',
    heart: 'Thả tim',
    view_profile: 'Xem profile',
    save: 'Lưu',
    repost: 'Đăng lại',
    follow: 'Theo dõi',
  },
  popup: {
    pricing: {
      title: 'Công cụ hỗ trợ tương tác trên ',
      sub: 'Tiktok',
      heading: 'BẢNG GIÁ',
      features: ['Không cần đăng nhập ID và mật khẩu Tiktok 🔒', 'AI viết lại nội dung miễn phí', 'Lưu nhóm yêu thích'],
      tier: 'Bậc',
      tiers: [
        {
          tier: 1,
          name: 'BASIC',
          cost: {
            month: 100000,
            year: 50000,
          },
          features: [
            {
              text: 'Bình luận',
              info: 'Bình luận',
            },
            {
              text: 'AI spin',
              info: 'Mỗi lần đăng bài, tool tự động dùng AI viết lại nội dung để né Spam.',
            },
            {
              text: 'Thả tim',
              info: 'Thả tim',
            },
            {
              text: 'Đăng lại',
              info: 'Đăng lại',
            },
            {
              text: 'Lưu nhóm người dùng yêu thích',
              info: 'Lưu nhóm người dùng yêu thích',
            },
            {
              text: 'Follow',
              info: 'Follow',
            },
            {
              text: 'Lưu video',
              info: 'Lưu lại',
            },
            {
              text: 'Xem profile',
              info: 'Xem profile',
            },
          ],
        },
      ],
      monthly: 'Hàng tháng',
      semi_annual: 'Nửa năm',
      annual: 'Hàng năm',
      month_value: '100K',
      annual_value: '50K',
      discount: 'Tiết kiệm tới {{percent}}%',
      bill_annual: 'Năm',
      bill_monthly: 'Tháng',
    },
  },
};

export default vn;
