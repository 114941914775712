import NoSleep from '@zakj/no-sleep';
// import { uniqBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, Stack } from '@mui/material';
import { ENVIRONMENT, SECRET_KEY, SMT_MAXIMUM_PARTS, SMT_MAXIMUM_WORDS } from '../config';
import useAuth from '../hooks/useAuth';

// hooks
import useBoolean from '../hooks/useBoolean';
import useGoogleLoginCustom from '../hooks/useGoogleLoginCustom';
import useLocales from '../hooks/useLocales';
import useRefCustom from '../hooks/useRefCustom';
import useTenant from '../hooks/useTenant';
import useToolFunction from '../hooks/useToolFunction';
import { useSelector } from '../redux/store';
import { modifyTime } from '../utils/formatTime';
import { compareVersions } from '../utils/others';
import {
  createTargetCollection,
  getTargetCollections,
  getLatestAssets,
  // getUserJobs,
  removeTargetCollection,
  removePostedContent,
} from '../utils/tool/api';

const FACEBOOK_URL = `https://www.facebook.com/`;

const VIDEO_UPLOAD_CONFIGS = 'video-upload-configs';

const MessageTypes = {
  CHECK_ACTIVE_EXTENSION: 'CHECK_ACTIVE_EXTENSION',
  GET_VERSION: 'GET_VERSION',
  CLEAR_CACHE: 'CLEAR_CACHE',
};

const MessageReceiveTypes = {
  R_CHECK_ACTIVE_EXTENSION: 'R_CHECK_ACTIVE_EXTENSION',
  R_GET_VERSION: 'R_GET_VERSION',
  R_CLEAR_CACHE: 'R_CLEAR_CACHE',
};

const initialState = {
  isLoading: false,
  ACTIONS_DELAY: { from: 20, to: 50 },
  SMT_MAXIMUM_PARTS,
  SMT_MAXIMUM_WORDS,
  isOpenWarningModal: false,
  isReleased: false,
  isOpenPricingModal: false,
  isLoginModal: false,
  isOpenInternetModal: false,
  isOpenWarningActionModal: false,
  extensionVersion: '',
  // Version
  isOpenExtensionModal: false,
  tenantExtensionVersion: '',
  isSecretModeOn: false,
  // Display
  UIDisplay: {},
  // No sleep
  noSleep: {},
  // modal, popup
  closeLoginModal: () => {},
  closePricingModal: () => {},
  closeWarningModal: () => {},
  closeInternetModal: () => {},
  closeWarningActionModal: () => {},
  openWarningActionModal: () => {},
  openInternetModal: () => {},
  openLoginModal: () => {},
  openPricingModal: () => {},
  // ------------------
  checkCommonData: () => {},
  onChangeSecretkey: () => {},
  onClearCache: () => {},
  onChangeUIDisplay: () => {},
  // Collections
  targetCollections: [],
  onCreateTargetCollection: () => {},
  onRemoveTargetCollection: () => {},
  // Assets
  postedContents: [],
  onRemoveContent: () => {},
  onLoadLatestAssets: () => {},
  // Task
  isTaskRunning: false,
  resetTaskRunning: () => {},
  updateTaskRunningStatus: () => {},
};

const ToolContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
ToolProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function ToolProvider({ children }) {
  // CACHE
  const onClearCache = () => {
    if (!isEnabledExtension.current) {
      return;
    }
    isLoading.onTrue();
    window.postMessage({ type: MessageTypes.CLEAR_CACHE }, '*');
    localStorage.removeItem('fb_info');
    localStorage.removeItem(VIDEO_UPLOAD_CONFIGS);
    window.open(FACEBOOK_URL, '_blank');
  };

  const { emitter, ACTIONS_DELAY, getExtensionVersion, checkExtensionActive, checkFacebookInternet } =
    useToolFunction();

  const { pathname } = useLocation();

  const isLoading = useBoolean();

  const [taskRunning, setTaskRunning] = useState({});

  const [postedContents, setPostedContents] = useState([]);

  const [targetCollections, setTargetCollections] = useState([]);

  const loginModal = useBoolean();

  const internetModal = useBoolean();

  const warningActionModal = useBoolean();

  const noSleep = new NoSleep();

  const loginWithGoogle = useGoogleLoginCustom({ redirectTo: window.location.href });

  const warningPopup = useBoolean();

  const isReleased = new Date() >= new Date('2024-04-21');

  const { user: userAuth } = useAuth();

  const [user, setUserRef, userRef] = useRefCustom(null);

  useEffect(() => {
    if (emitter) {
      const handleCheckpoint = () => {
        warningPopup.onTrue();
      };

      const handleRetry = () => {
        warningActionModal.onTrue();
      };

      const handleUploadMediaFailed = () => {
        enqueueSnackbar(translate('upload_media_error'), {
          variant: 'warning',
          autoHideDuration: 30000,
          action: (
            <Stack onClick={onClearCache}>
              <Link href="#" color="secondary" size="small">
                {translate('btn.fix')}
              </Link>
            </Stack>
          ),
        });
      };

      emitter?.on('upload-media-failed', handleUploadMediaFailed);
      emitter?.on('checkpoint', handleCheckpoint);
      emitter?.on('retry', handleRetry);

      return () => {
        emitter?.off('upload-media-failed', handleUploadMediaFailed);
        emitter?.off('checkpoint', handleCheckpoint);
        emitter?.off('retry', handleRetry);
      };
    }
  }, [emitter]);

  useEffect(() => {
    setUserRef(userAuth);
  }, [userAuth]);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [extensionVersion, setExtensionVersion] = useState(null);

  const isEnabledExtension = useRef(false);

  const [currentSecretKey, setCurrentSecretKey] = useState(null);

  const [UIDisplay, setUIDisplay] = useState({ displayContent: true, displayComment: false });

  const checker = useRef();

  const extensionModal = useBoolean();

  const promoPopup = useBoolean();

  const isSecretModeOn = currentSecretKey && currentSecretKey === SECRET_KEY && ENVIRONMENT === 'dev';

  // Tenant
  const { isSMT } = useTenant();

  const { currentTenant } = useSelector((state) => state.tenant);

  const tenantExtensionVersion = currentTenant?.config?.extension_version;

  const shouldUpdateExtension = useMemo(
    () => {
      // allow later versions
      if (isSMT && tenantExtensionVersion && extensionVersion) {
        console.log('tenantExtensionVersion: ', tenantExtensionVersion);
        console.log('webExtensionVersion: ', extensionVersion);
        const diff = compareVersions(extensionVersion, tenantExtensionVersion);

        return diff < 0;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantExtensionVersion, isSMT, extensionVersion]
  );

  useEffect(() => {
    if (shouldUpdateExtension) {
      enqueueSnackbar(translate('update_extension'), {
        variant: 'warning',
        autoHideDuration: 5000,
        action: (
          <Link target="_blank" href="https://doc.fbtool.net/" color="secondary" size="small">
            {translate('tool.btn.more')}
          </Link>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateExtension]);

  const canUseFeaturesRef = useRef();

  useEffect(() => {
    canUseFeaturesRef.current =
      userRef?.current?.fbtool?.is_bought_lifetime ||
      (userRef?.current?.fbtool?.expired_at && modifyTime(userRef?.current?.fbtool?.expired_at) >= new Date());
  }, [user]);

  const handleMessage = (event) => {
    if (event.source === window && event.data) {
      const { type } = event.data || {};
      // Check receive types
      if (Object.values(MessageReceiveTypes).indexOf(type) === -1) {
        return;
      }
      switch (type) {
        // RECEIVE RESULT CLEAR CACHES
        case MessageReceiveTypes.R_CLEAR_CACHE: {
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          break;
        }
        default:
          break;
      }
    }
  };

  const checkEnabledExtension = () => {
    if (!isEnabledExtension.current) {
      extensionModal.onTrue();
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    if (isReleased) {
      checker.current = setInterval(checkEnabledExtension, 5000);
    }
    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckActiveExtension = async () => {
    const payload = await checkExtensionActive();
    if (payload?.is_active) {
      isEnabledExtension.current = true;
      if (checker.current) {
        clearInterval(checker.current);
      }
    }
  };

  const onLoadExtensionVersion = async () => {
    const payload = await getExtensionVersion();
    if (payload?.version) {
      setExtensionVersion(payload?.version);
    }
    if (payload?.isDuplicate) {
      enqueueSnackbar(translate('duplicate_extension'), {
        variant: 'warning',
        autoHideDuration: 30000,
        action: (
          <Stack direction="row" gap={2} alignItems="center">
            <Stack onClick={onClearCache}>
              <Link href="#" color="secondary" size="small">
                {translate('btn.reload')}
              </Link>
            </Stack>
            <Link
              target="_blank"
              href="https://doc.fbtool.net/#buoc-2-tai-va-cai-dat-tien-ich-mo-rong"
              color="secondary"
              size="small"
            >
              {translate('tool.btn.more')}
            </Link>
          </Stack>
        ),
      });
    }
  };

  const onCheckInternet = async () => {
    const isInternetFromMobileDevice = await checkFacebookInternet();
    if (isInternetFromMobileDevice) {
      internetModal.onTrue();
    }
  };

  useEffect(() => {
    onCheckActiveExtension();
    onLoadExtensionVersion();
    onCheckInternet();
    if (user) {
      onLoadLatestAssets();
      onLoadCollections();
    }
  }, [user]);

  const checkCommonData = useCallback(() => {
    if (isSecretModeOn) {
      return true;
    }

    if (!userRef?.current) {
      loginWithGoogle();
      return false;
    }

    if (!canUseFeaturesRef?.current) {
      enqueueSnackbar(translate('usage_error'), { variant: 'error' });
      return false;
    }

    if (shouldUpdateExtension) {
      enqueueSnackbar(translate('update_extension'), {
        variant: 'warning',
        autoHideDuration: 5000,
        action: (
          <Link target="_blank" href="https://doc.fbtool.net/" color="secondary" size="small">
            {translate('tool.btn.more')}
          </Link>
        ),
      });
      return false;
    }

    return true;
  }, [isSecretModeOn, user, shouldUpdateExtension]);

  // SECRET MODE
  const onChangeSecretkey = (secretKey) => {
    setCurrentSecretKey(secretKey);
  };

  // UI Display
  const onChangeUIDisplay = (name, value = 'empty') => {
    if (value !== 'empty') {
      setUIDisplay((prev) => ({ ...prev, [name]: value }));
    } else {
      setUIDisplay((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  // CONTENT
  const onLoadLatestAssets = async () => {
    try {
      const data = await getLatestAssets();
      setPostedContents([...data]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.details || translate('server_error'), {
        variant: 'error',
      });
    }
  };

  const onRemoveContent = async (uuid) => {
    if (!checkCommonData()) {
      return;
    }

    try {
      const temp = [...postedContents];
      const index = temp?.findIndex((item) => item?.uuid === uuid);
      if (index !== -1) {
        temp.splice(index, 1);
        setPostedContents(temp);
      }
      await removePostedContent(uuid);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.details || translate('server_error'), {
        variant: 'error',
      });
    }
  };

  // COLLECTIONS
  const onLoadCollections = async () => {
    try {
      // groups
      const temp1 = await getTargetCollections();
      // friends
      const temp2 = await getTargetCollections('friend');
      setTargetCollections([...temp1, ...temp2]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        error?.messages || error?.message || error?.details || error?.detail || translate('server_error'),
        {
          variant: 'error',
        }
      );
    }
  };

  const onRemoveTargetCollection = async (id) => {
    if (!checkCommonData()) {
      return;
    }

    try {
      const temp = [...targetCollections];
      const index = temp?.findIndex((item) => item?.id === id);
      if (index !== -1) {
        temp.splice(index, 1);
        setTargetCollections(temp);
      }
      await removeTargetCollection(id);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.details || translate('server_error'), {
        variant: 'error',
      });
    }
  };

  const onCreateTargetCollection = async (payload, type = 'group') => {
    if (!checkCommonData()) {
      return;
    }

    try {
      const temp = await createTargetCollection(payload, type);
      setTargetCollections((prev) => [...prev, temp]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.details || translate('server_error'), {
        variant: 'error',
      });
    }
  };

  // Manage task running for block redirect
  const updateTaskRunningStatus = (value) => {
    setTaskRunning((prev) => ({ ...prev, [pathname]: value }));
  };

  const resetTaskRunning = () => {
    setTaskRunning({});
  };

  const isTaskRunning = useMemo(() => Object.keys(taskRunning)?.some((path) => taskRunning[path]), [taskRunning]);

  return (
    <ToolContext.Provider
      value={useMemo(
        () => ({
          isLoading: isLoading?.value,
          isTaskRunning,
          resetTaskRunning,
          updateTaskRunningStatus,
          // ======
          ACTIONS_DELAY,
          SMT_MAXIMUM_PARTS,
          SMT_MAXIMUM_WORDS,
          isOpenWarningModal: warningPopup.value,
          isReleased,
          isOpenPricingModal: promoPopup.value,
          isLoginModal: loginModal.value,
          isOpenInternetModal: internetModal.value,
          isOpenWarningActionModal: warningActionModal?.value,
          extensionVersion,
          // Version
          isOpenExtensionModal: extensionModal?.value || shouldUpdateExtension,
          tenantExtensionVersion,
          isSecretModeOn,
          // Display
          UIDisplay,
          // No sleep
          noSleep,
          closeLoginModal: loginModal.onFalse,
          closePricingModal: promoPopup.onFalse,
          closeWarningModal: warningPopup.onFalse,
          closeWarningActionModal: warningActionModal.onFalse,
          closeInternetModal: internetModal.onFalse,
          openInternetModal: internetModal.onTrue,
          openPricingModal: promoPopup.onTrue,
          openWarningActionModal: warningActionModal.onTrue,
          openLoginModal: loginWithGoogle,
          checkCommonData,
          onChangeSecretkey,
          onClearCache,
          onChangeUIDisplay,
          // Group collections
          targetCollections,
          onCreateTargetCollection,
          onRemoveTargetCollection,
          onLoadCollections,
          // Assets
          postedContents,
          onRemoveContent,
          onLoadLatestAssets,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          ACTIONS_DELAY,
          loginModal,
          extensionModal?.value,
          promoPopup?.value,
          isSecretModeOn,
          shouldUpdateExtension,
          extensionVersion,
          tenantExtensionVersion,
          UIDisplay,
          warningPopup.value,
          warningActionModal?.value,
          noSleep,
          postedContents,
          targetCollections,
          isLoading?.value,
        ]
      )}
    >
      {children}
    </ToolContext.Provider>
  );
}

export { ToolProvider, ToolContext };
